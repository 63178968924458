
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList,getPageAPIs } from "@/common/util";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import {OrderApi} from "@/common/api/userManage/orderList";

import { excuteDownloadExport2Excel } from "@/common/xlsx";
export const pageApiList = getPageAPIs(OrderApi);

import {
  userLevel2ch,
  getCurrentMonthRange
} from '@/common/config'


// function getLastMonthDate(range: number = 1) {
//   const lastEndDay = (dayjs().startOf("month") as any).format("YYYY-MM-DD HH:mm:ss");

//   const lastStartDay = (
//     dayjs().subtract(range, "month").endOf("month") as any
//   ).format("YYYY-MM-DD  HH:mm:ss");

//   return [lastStartDay, lastEndDay];
// }

const defaultForm = {
  searchKw: "", // 搜索订单号
  pay_no: "", // 副订单编号
  order_id: "", // 订单ID
  // shop_id: "", // 店铺ID
  shop_name: "", // 店铺名称
  user_id: "", // 用户ID
  listSortType: -1, // 顾客订单状态
  orderPlaformType: -1, // 类别

  channelId: "",
  create_time: [
    ...getCurrentMonthRange(2, 'YYYY-MM-DD HH:mm:ss')
  ], // 创建时间
  finish_time: [], // 完成时间
};

const orderDeviceType2ch: any = {
  0: "未知",
  1: "ios",
  2: "安卓",
  3: "小程序",
};

// const payType2ch: any = {
//   "1": "团购券",
//   "2": "单店会员",
//   "3": "全国会员",
//   "4": "押金支付",
// };

// 下单方式类型：1用户自助下单（扫描开台） 2商家端下单 3收银台下单 4排队抢台下单 5用户抢台下单（直接下单）
const orderMethod2Ch: string[] = ['', '用户自助下单（扫码下单）', '商家端下单', '收银台下单', '排队抢台西单', '用户抢台下单（直接下单）']


// 计费方式:1、分钟计费 2、半小时计费 3、小时计费 4、开台不足1小时按小时计费、超过1小时按分钟计费 5、自定义
const orderFeeMethod2Ch: string[] = ['', '分钟计费', '半小时计费', '小时计费', '开台不足1小时按小时计费、超过1小时按分钟计费', '自定义']

function showOrderFeeInfo(method: number, loop: number, start: number) {
  if (method === 5) {
    return `以${loop}分钟作为一个收费周期，第${start}分钟后开始收费`
  } else {
    return orderFeeMethod2Ch[method] ? `按${orderFeeMethod2Ch[method]}` : '未知方式'
  }
}

@Component({
  filters: {
    // 支付方式：1微信小程序 2微信app 3支付宝app,4=账户支付,6单店卡支付
    showOtherPayInfoTxt({
      pay_type,
      vip_discount,
      price
    }: any) {
      let payInfo = ''

      const prefixLabels = [
        '',
        '微信¥',
        '微信APP¥',
        '支付宝¥',
        '全国会员¥',
        '',
        '单店会员¥'
      ]

      payInfo += `${prefixLabels[pay_type]}${price}`

      if (pay_type == 4) {
        payInfo += `（${vip_discount}折）`
      }

      return payInfo
    },

    // 下单方式类型：1用户自助下单（扫描开台） 2商家端下单 3收银台下单 4排队抢台下单 5用户抢台下单（直接下单）
    showPaySceneInfo(method: number) {
      return orderMethod2Ch[method] || '未知场景'
    },

    showOrderFeeInfo
  }
})
export default class OrderList extends Vue {
  private orderApi: any;

  private deepLoopUtil: any;

  constructor() {
    super();
    this.orderApi = new OrderApi();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
  }

  private form: any;

  private firstLoading: boolean = true;
  // private chooseTechnicianItem : any = null;

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  // private show: boolean = false;
  // 状态：1 提交订单 2 已付款 3 已取消 4 已开始 5 已完成 6 部分退款
  private sortTypeList: any[] = [
    {
      value: -1,
      label: "全部",
    },
    {
      label: "提交订单",
      value: 1,
    },
    {
      label: "已付款",
      value: 2,
    },
    {
      label: "已取消",
      value: 3,
    },
    {
      label: "已开始",
      value: 4,
    },
    {
      label: "已完成",
      value: 5,
    },
    {
      label: "部分退款",
      value: 6,
    },
    // {
    //   label: "申请退款成功",
    //   value: 7,
    // },
    // {
    //   label: "退款失败",
    //   value: 8,
    // },
    // {
    //   label: "申请退款",
    //   value: 9,
    // },
    // {
    //   label: "申请取消订单",
    //   value: 10,
    // },
  ];

  

  private orderPlaformTypeList: any = [
    {
      label: "全部",
      value: -1,
    },
    {
      label: "ios",
      value: 1,
    },
    {
      label: "安卓",
      value: 2,
    },
    {
      label: "小程序",
      value: 3,
    },
  ];

  private activated(): void {
    this.getTable();
 
  }

  private getTable(): void {
    this.orderApi.getOrderTableList(
      this.getOrderTableListParams(),
      (res: any) => {
        console.log(res, "res");
        // this.tableData = res.data;
        const { data } = res;

        const { list, count, page, prepage } = data || {};

        const newTableList = Array.isArray(list)
          ? list.map(this.handleTableData)
          : [];

        // totalCount: 0,
        // pageNo: 1,
        // pageSize: 10,
        this.$set(this.tablePage, "totalCount", count);
        this.$set(this.tablePage, "pageNo", page);
        this.$set(this.tablePage, "pageSize", prepage);

        this.tableData = newTableList;

        this.firstLoading = false;
      },
      () => {
        this.firstLoading = false;
      },
      false
    );
  }

  private getOrderTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const firstLoading = this.firstLoading;

    const {
      searchKw, // 搜索订单号
      pay_no, // 副订单编号
      order_id, // 订单ID
      // shop_id, // 店铺ID
      shop_name, // 店铺名称
      user_id, // 用户ID
      
      listSortType, // 顾客订单状态

      orderPlaformType, // 类别
      channelId,
      user_phone,
      create_time, // 创建时间
      finish_time, // 完成时间
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (searchKw) {
      outerParams.order_no = searchKw;
    }

    if (pay_no) {
      outerParams.pay_no = pay_no
    }

    if (order_id) {
      outerParams.id = order_id;
    }

    if (user_id) {
      outerParams.user_id = user_id - 0;
    }

    // if (shop_id) {
    //   outerParams.shop_id = shop_id - 0;
    // }

    if (shop_name) {
      outerParams.shop_name = shop_name;
    }

    if (orderPlaformType != -1 && typeof orderPlaformType == "number") {
      outerParams.device = orderPlaformType - 0;
    }

    if (channelId) {
      outerParams.channelId = channelId;
    }
    if (listSortType != -1 && typeof listSortType == "number") {
      outerParams.status = listSortType;
    }

    if (user_phone != "") {
      outerParams.user_phone = user_phone;
    }
    if (Array.isArray(create_time) && create_time.length === 2) {
      const [startDate, endDate] = create_time;

      const startTime = Util.dateTimeUtil(startDate);
      const endTime = Util.dateTimeUtil(endDate);

      outerParams.created_start_time = startTime;

      outerParams.created_end_time = endTime;
    }

    if (Array.isArray(finish_time) && finish_time.length === 2) {
      const [startDate, endDate] = finish_time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.over_start_time = startTime;

      outerParams.over_end_time = endTime;
    }

    const route = this.$route;

    const { query } = route;

    const { userId } = query;

    if (userId) {
      outerParams.userId = userId;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.created_start_time && outerParams.created_end_time)
    ) {
      this.$message.error("请选择创建时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  private getMutiPayList(orderItem: any) {
    const list = []

    const {
      order_no,
      remit_type, // 结账方式：0下单用户结账 1AA付款 2对方付款
      multi_pay
    } = orderItem || {}

    if (remit_type === 0) return []

    list.push(
      ...(multi_pay || []).map((item: any) => {
        const {
          remit_type, // 结账方式：0原订单支付(追账) 1AA付款 2对方付款
          is_debt // 债务：0正常结账 1欠款
        } = item || {}

        const newItem = {
          ...item
        }

        if (is_debt === 1) {
          newItem.__pay_label = '追账'
        } else if (remit_type !== 0) {
          newItem.__pay_label = remit_type === 1 ? 'AA付' : '他人付'
        } else {
          return {}
        }



        return newItem
      }).filter((item: any) => {
        const {
          pay_no
        } = item || {}

        return pay_no && pay_no !== order_no
      })
    )

    return list
  }

  // private getOrderTotalMins(row: any) {
  //   const { daytime_minute, night_minute } = row || {};

  //   function tranformMinNums(mins: any) {
  //     return isNaN(Number(mins)) ? 0 : Number(mins);
  //   }

  //   return tranformMinNums(daytime_minute) + tranformMinNums(night_minute);
  // }


  private getOrderSpendMoney(row: any) {
    const { price, refunded_price } = row;

    return (price - refunded_price).toFixed(2);
  }


  // 后台返回的字段 转化为前端定义的字段
  private handleTableData(data: any): any {
    const { device, custom_pay_type, user_type } = data;
    const newData = {
      ...data,
      device_name: orderDeviceType2ch[device],
      // pay_type_name: payType2ch[custom_pay_type],
      user_level_type_name: userLevel2ch[user_type],
    };

    return newData;
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 订单字段名 文案对应关系
  private orderExcelList2ch: any = {
    id: "订单ID",
    order_no: "订单编号",
    created_at: "创建时间",
    pay_time: "付款时间",
    cancel_time: "取消时间",
    over_time: "结束时间",
    reserve_start: "预约开始时间",
    reserve_end: "预约结束时间",
    user_name: "用户昵称",
    user_id: "用户ID",
    user_phone: "用户手机号",
    user_level_type_name: "用户会员等级",
    shop_id: "店铺ID",
    contact_phone: "店铺联系方式",
    pool_name: "预约台桌名",
    shop_address: "店铺地址",
    daytime_price: "店铺白天价格",
    nighttime_price: "店铺晚上价格",
    device_name: "下单渠道",
    status_name: "顾客订单状态",
    original_price: "费用",
    coupon_time: "优惠时长(分钟)",
    vip_discount: "会员折扣（折）",
    custom_pay_type: "支付渠道",
    __order_fee_method: '计费方式',
    price: "实际支付金额",
    source: "卡券类型",
    coupons_name: "卡券名称",
    divide_price: "卡券价格",
    fencheng_rate: "商家分成比例",
    income: "商家最终收益",
    refunded_minute: "退款时长(分钟)",
    actual_price: "扣款",
    refunded_price: "退回金额",
  };
  // 订单Excel 表头的先后顺序
  private orderExcelSortList: string[] = [
    "id",
    "order_no",
    "created_at",
    "pay_time",
    "cancel_time",
    "over_time",
    "reserve_start",
    "reserve_end",
    "user_name",
    "user_id",
    "user_phone",
    "user_level_type_name",
    "shop_id",
    "contact_phone",
    "pool_name",
    "shop_address",
    "daytime_price",
    "nighttime_price",
    "device_name",
    "status_name",
    "original_price",
    "coupon_time",
    "vip_discount",
    "custom_pay_type",
    "__order_fee_method",
    "price",
    "source",
    "divide_price",
    "coupons_name",
    "fencheng_rate",
    "income",
    "refunded_minute",
    "actual_price",
    "refunded_price",
  ];

  // 点击导出excel 表格
  private handleDownloadExcel() {
    const params = this.getOrderTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.orderApi.exportExcelOrderList(
      params,
      (res: any) => {
        const {
          created_start_time,
          created_end_time,
          over_start_time,
          over_end_time,
        } = params || {};

        const create_time_duration_name =
          created_start_time && created_end_time
            ? `创建时间${created_start_time}到${created_end_time}`
            : "";

        const finish_time_duration_name =
          over_start_time && over_end_time
            ? `完成时间${over_start_time}到${over_end_time}`
            : "";
        const filename = `订单列表 ${create_time_duration_name} ${finish_time_duration_name}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const {
                user_type,
                price,
                refunded_price,
                device,

                // order_no,

                // custom_pay_type,
                // daytime_minute,
                // night_minute,
                fee_method,
                fee_start,
                fee_loop
              } = item || {};

              const otherOrderList = this.getMutiPayList(item).map(({ __pay_label, pay_no }: any) => {
                return `${__pay_label}：${pay_no}`
              })

              const newItem = {
                ...(item || {}),
                device_name: orderDeviceType2ch[device],
                // pay_type_name: payType2ch[custom_pay_type],
                // total_miniute: daytime_minute + night_minute,
                actual_price: price - refunded_price || "",
                user_level_type_name: userLevel2ch[user_type],

                __order_fee_method: showOrderFeeInfo(fee_method, fee_loop, fee_start)
              };

              if (otherOrderList.length) {
                newItem.order_no += ',' + otherOrderList.join(',')
              }

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const orderExcelSortList = this.orderExcelSortList;

          const orderExcelList2ch = this.orderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            orderExcelSortList,
            orderExcelList2ch
          );

          // console.log(newList, "newList");

          // console.log(excelJSONData, "excelJSONData");

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}
